<template>
    <div id="app">
        <div v-if="flag" style="width:100%;height:1800px;background-color: rgba(0, 0, 0, 0.577);position: absolute;"
            @click="flag = false">
        </div>
        <div class="box">
            <div v-show="flag" style="position: fixed;left:50%;top: 50%; z-index: 20; transform: translate(-50%,-50%);">
                <div @click="flag=false"
                    style="position: absolute;right: -30px; font-size:30px;font-weight:700;background-color: #fff;width: 50px;height: 50px;border-radius: 50%;text-align: center;line-height: 45px;cursor: pointer;">
                    x</div>
                <video ref="videoPlay" style="z-index:22" width="780" height="480" controls poster="./assets/123.jpg"
                    :src="urls" autoplay> </video>
            </div>
            <header class="header"></header>
            <main class="banner">
                <div>
                    <img src="./assets/123.jpg" alt="">
                </div>
                <div style="margin-top:-4px;position: relative;" v-if="!flag">
                    <img src="./assets/321.jpg" alt="">
                    <div style="position:absolute;left: 50%;top:5%;transform: translate(-50%);color: white;">
                        <div>
                            <h1>合肥市预防未成年人违法犯罪微视频微动漫获奖作品展播</h1>
                            <p style="margin-top:5px;display: block;width:1050px;">为贯彻落实习近平法治思想，深入开展未成年人权益保护工作，
                                扎实推进全市预防未成年人违法犯罪专项行动，
                                市委平安办、市教育局、市人力资源和社会保障局在全市中职（技工）学校开展了预防未成年人违法犯罪“奋斗新时代，
                                绽放新风采”主题微动漫微视频作品征集活动。经专家评审，
                                确定微动漫、微视频获奖作品共20部，
                                其中微动漫一等奖1名，
                                二等奖3名，
                                三等奖6名；
                                微视频一等奖1名，
                                二等奖3名，
                                三等奖6名。具体获奖作品详见下方列表。
                            </p>
                        </div>
                    </div>
                </div>
            </main>
            <div class="bodys content">
                <div>
                    <img src="./assets/dongman.jpg" alt="">
                </div>
                <div class="bodys_box">
                    <div
                        style="width:100%;height:80px;line-height: 80px; background-color: red;display: flex;color: white; font-weight: 700;font-size: 20px;">
                        <div style="flex:1;" v-for="(item, index) in arr" :key="index" @click="check1(index)"
                            :class="{ 'color': active1 == index }">{{ item.label }} </div>
                        <!-- <div style="flex:1">二等奖</div>
    <div style="flex:1">三等奖</div> -->
                    </div>
                    <ul>
                        <li v-for="(item, index) in dmArr" v-show="item.flag" :key="index" @click="checkUrl(item)">
                            <div>
                                <a href="javascript:;">
                                    <video :src="item.url" width="260" height="150" 
                                        poster="./assets/123 - 副本.jpg"></video>
                                    <p><strong>作品奖项：</strong>{{ item.jiang }}</p>
                                    <p><strong>作品名称：</strong>{{ item.name }}</p>
                                </a>
                            </div>
                        </li>
                        <!-- <li v-if="flag4">
            <a href="javascript:;">
                
           <video src="./assets/dongman/二等奖-《“文身”之害》_batch.mp4" width="260" height="150"  poster="./assets/123 - 副本.jpg" controls></video>
            <p><strong>作品奖项：</strong>二等奖</p>
            <p><strong>作品名称：</strong>《“文身”之害》</p>
        </a>
        </li>
        <li v-if="flag4">
            <a href="javascript:;">
                
           <video src="./assets/dongman/二等奖-《人生的岔路口》_batch.mp4" width="260" height="150"  poster="./assets/123 - 副本.jpg" controls></video>
            <p><strong>作品奖项：</strong>二等奖</p>
            <p><strong>作品名称：</strong>《人生的岔路口》</p>
        </a>
        </li>
        <li v-if="flag4">
            <a href="javascript:;">
                
           <video src="./assets/dongman/二等奖-《关爱》_batch.mp4" width="260" height="150"  poster="./assets/123 - 副本.jpg" controls></video>
            <p><strong>作品奖项：</strong>二等奖</p>
            <p><strong>作品名称：</strong>《关爱》</p>
        </a>
        </li>
        <li v-if="flag5">
            <a href="javascript:;">
                
           <video src="./assets/dongman/三等奖-《预防校园欺凌》_batch.mp4" width="260" height="150"  poster="./assets/123 - 副本.jpg" controls></video>
            <p><strong>作品奖项：</strong>三等奖</p>
            <p><strong>作品名称：</strong>《预防校园欺凌》</p>
        </a>
        </li>
        <li v-if="flag5">
            <a href="javascript:;">
                
           <video src="./assets/dongman/三等奖-《做社会主义新青年》_batch.mp4" width="260" height="150"  poster="./assets/123 - 副本.jpg" controls></video>
            <p><strong>作品奖项：</strong>三等奖</p>
            <p><strong>作品名称：</strong>《做社会主义新青年》</p>
        </a>
        </li>
        <li v-if="flag5">
            <a href="javascript:;">
                
           <video src="./assets/dongman/三等奖-《预防未成年人犯罪科普讲堂》_batch.mp4" width="260" height="150"  poster="./assets/123 - 副本.jpg" controls></video>
            <p><strong>作品奖项：</strong>三等奖</p>
            <p><strong>作品名称：</strong>《预防未成年人犯罪科普讲堂》</p>
        </a>
        </li>
        <li v-if="flag5">
            <a href="javascript:;">
                
           <video src="./assets/dongman/三等奖-《树立正确价值观》_batch.mp4" width="260" height="150"  poster="./assets/123 - 副本.jpg"  controls></video>
            <p><strong>作品奖项：</strong>三等奖</p>
            <p><strong>作品名称：</strong>《树立正确价值观》</p>
        </a>
        </li>
        
        <li v-if="flag5">
            <a href="javascript:;">
                
           <video src="./assets/dongman/三等奖-《迷茫》_batch.mp4" width="260" height="150"  poster="./assets/123 - 副本.jpg" controls></video>
            <p><strong>作品奖项：</strong>三等奖</p>
            <p><strong>作品名称：</strong>《迷茫》</p>
        </a>
        </li>
        <li v-if="flag5">
            <a href="javascript:;">
                
           <video src="./assets/dongman/三等奖-《预防未成年人违法犯罪》_batch.mp4" width="260" height="150"  poster="./assets/123 - 副本.jpg" controls></video>
            <p><strong>作品奖项：</strong>三等奖</p>
            <p><strong>作品名称：</strong>《预防未成年人违法犯罪》</p>
        </a>
        </li> -->

                    </ul>
                </div>
            </div>
            <div class="bodys content">
                <div>
                    <img src="./assets/wship.jpg" alt="">
                </div>
                <div class="bodys_box">
                    <div
                        style="width:100%;height:80px;line-height: 80px; background-color: red;display: flex;color: white; font-weight: 700;font-size: 20px;">
                        <div style="flex:1;" v-for="(item, index) in arr" :key="index" @click="check(index)"
                            :class="{ 'color': active == index }">{{ item.label }} </div>
                        <!-- <div style="flex:1">二等奖</div>
    <div style="flex:1">三等奖</div> -->
                    </div>
                    <ul>
                        <li v-for="(item, index) in spArr" :key="index" v-show="item.flag" @click="checkUrl(item)">
                            <a href="javascript:;">
                                <!-- <img src="../assets/123.jpg" alt="" width="260" height="150"> -->
                                <!-- <div style="width:260px;height:150px"> -->
                                <!-- :src="item.url" -->
                                <!-- controls -->
                                <video :src="item.url" poster="./assets/123 - 副本.jpg" width="260" height="150">
                                </video>
                                <!-- </div> -->
                                <p><strong>作品奖项：</strong>{{ item.jiang }}</p>
                                <p><strong>作品名称：</strong>{{ item.name }}</p>
                            </a>
                        </li>


                    </ul>
                </div>
            </div>

        </div>

        <div style="width: 1200px;height: 1px;margin: 15px auto;font-size: 0;border-bottom: #E70D11 solid 7px;clear: both;">
        </div>
        <div style="text-align:center;font-size: 13px;margin-bottom: 100px;">
            <p>主办单位：中共合肥市委平安合肥建设领导小组办公室、合肥市教育局、合肥市人力资源和社会保障局</p>
        </div>

    </div>
</template>

<script>

export default {
    data() {
        return {
            flag: false,
            urls: '',
            active: 0,
            active1: 0,
            arr: [
                {
                    label: '一等奖'
                },
                {
                    label: '二等奖'
                },
                {
                    label: '三等奖'
                },
            ],
            dmArr: [
                {
                    name: '《窃》',
                    jiang: '一等奖',
                    url: require('./assets/dongman/一等奖-《窃》_batch.mp4'),
                    flag: true
                },
                {
                    name: '《“文身”之害》',
                    jiang: '二等奖',
                    url: require('./assets/dongman/二等奖-《“文身”之害》_batch.mp4'),
                    flag: true
                },
                {
                    name: '《人生的岔路口》',
                    jiang: '二等奖',
                    url: require('./assets/dongman/二等奖-《人生的岔路口》_batch.mp4'),
                    flag: true
                },
                {
                    name: '《关爱》',
                    jiang: '二等奖',
                    url: require('./assets/dongman/二等奖-《关爱》_batch.mp4'),
                    flag: true
                },
                {
                    name: '《预防校园欺凌》',
                    jiang: '三等奖',
                    url: require('./assets/dongman/三等奖-《预防校园欺凌》_batch.mp4'),
                    flag: true
                },
                {
                    name: '《做社会主义新青年》',
                    jiang: '三等奖',
                    url: require('./assets/dongman/三等奖-《做社会主义新青年》_batch.mp4'),
                    flag: true
                },
                {
                    name: '《预防未成年人犯罪科普讲堂》',
                    jiang: '三等奖',
                    url: require('./assets/dongman/三等奖-《预防未成年人犯罪科普讲堂》_batch.mp4'),
                    flag: true
                },
                {
                    name: '《树立正确价值观》',
                    jiang: '三等奖',
                    url: require('./assets/dongman/三等奖-《树立正确价值观》_batch.mp4'),
                    flag: true
                },
                {
                    name: '《迷茫》',
                    jiang: '三等奖',
                    url: require('./assets/dongman/三等奖-《迷茫》_batch.mp4'),
                    flag: true
                },
                {
                    name: '《预防未成年人违法犯罪》',
                    jiang: '三等奖',
                    url: require('./assets/dongman/三等奖-《预防未成年人违法犯罪》_batch.mp4'),
                    flag: true
                },
            ],
            spArr: [
                {
                    name: '《防微杜渐》',
                    jiang: '一等奖',
                    url: require('./assets/shipin/一等奖-《防微杜渐》_batch.mp4'),
                    flag: true
                },
                {
                    name: '《悔》',
                    jiang: '二等奖',
                    url: require('./assets/shipin/二等奖-《悔》_batch.mp4'),
                    flag: true
                }
                ,
                {
                    name: '《舌头没有骨 却能杀人》',
                    jiang: '二等奖',
                    url: require('./assets/shipin/二等奖-《舌头没有骨却能杀人》_batch.mp4'),
                    flag: true
                }
                ,
                {
                    name: '《雾》',
                    jiang: '二等奖',
                    url: require('./assets/shipin/二等奖-《雾》_batch.mp4'),
                    flag: true
                }
                ,
                {
                    name: '《长风浩荡》',
                    jiang: '三等奖',
                    url: require('./assets/shipin/三等奖-《长风浩荡》_batch.mp4'),
                    flag: true
                }
                ,
                {
                    name: '《Du毒》',
                    jiang: '三等奖',
                    url: require('./assets/shipin/三等奖-《Du毒》_batch.mp4'),
                    flag: true
                }
                ,
                {
                    name: '《预防未成年人电信诈骗》',
                    jiang: '三等奖',
                    url: require('./assets/shipin/三等奖-《预防未成年人电信诈骗》_batch.mp4'),
                    flag: true
                }
                ,
                {
                    name: '《关爱之光 照亮未来》',
                    jiang: '三等奖',
                    url: require('./assets/shipin/三等奖-《关爱之光 照亮未来》_batch.mp4'),
                    flag: true
                }
                ,
                {
                    name: '《反对校园欺凌构建和谐校园》',
                    jiang: '三等奖',
                    url: require('./assets/shipin/三等奖-《反对校园欺凌构建和谐校园》_batch.mp4'),
                    flag: true
                }
                ,
                {
                    name: '《青春砥砺奋进》',
                    jiang: '三等奖',
                    url: require('./assets/shipin/三等奖-《青春砥砺奋进》_batch.mp4'),
                    flag: true
                }
            ]
        }
    },
    created() {
        this.check1(0)
        this.check(0)
    },
    methods: {
        checkUrl(obj) {
            let _that = this
            // console.log(obj);
            _that.flag = true
            //   console.log(item.url); 
            console.log(this.$refs.videoPlay);
            _that.$refs.videoPlay.src = obj.url;
            console.log(this.$refs.videoPlay.src);
            _that.$nextTick(() => {
                _that.$refs.videoPlay.load()
            })
        },
        check(index) {
            this.active = index
            if (this.active == 0) {
                this.spArr.forEach(item => {
                    if (item.jiang == '一等奖') {
                        item.flag = true
                    }
                    else {
                        item.flag = false
                    }

                })
            }
            else if (this.active == 1) {
                this.spArr.forEach(item => {
                    if (item.jiang == '二等奖') {
                        item.flag = true
                    }
                    else {
                        item.flag = false
                    }

                })
            }
            else if (this.active == 2) {
                this.spArr.forEach(item => {
                    if (item.jiang == '三等奖') {
                        item.flag = true
                    }
                    else {
                        item.flag = false
                    }

                })
            }
        },
        check1(index) {
            this.active1 = index
            if (this.active1 == 0) {
                this.dmArr.forEach(item => {
                    if (item.jiang == '一等奖') {
                        item.flag = true
                    }
                    else {
                        item.flag = false
                    }

                })
            }
            else if (this.active1 == 1) {
                this.dmArr.forEach(item => {
                    if (item.jiang == '二等奖') {
                        item.flag = true
                    }
                    else {
                        item.flag = false
                    }

                })
            }
            else if (this.active1 == 2) {
                this.dmArr.forEach(item => {
                    if (item.jiang == '三等奖') {
                        item.flag = true
                    }
                    else {
                        item.flag = false
                    }

                })
            }
        }
    }
}
</script>
<style>
.color {
    background-color: pink;
}

* {
    margin: 0;
    padding: 0;
}

.content {
    width: 1200px;
    margin: 0 auto;
}

.box {
    width: 100%;
    text-align: center;
    padding-bottom: 100px;
}

.header {
    background-color: red;
    height: 40px;
    width: 100%;

}

.bodys {
    margin-top: 20px;
}

.bodys_box {
    margin-top: 10px;
}

.banner {
    text-align: center;
}

ul li {
    list-style: none;
    float: left;
    width: 21%;
    height: 225px;
    margin: 20px 19px;
}

a {
    color: black;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
    color: red;
}

ul li p {
    text-align: start;
    font-size: 14px;
    line-height: 25px;
    height: 25px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

@media screen and (max-width: 768px) {
    .header {
        background-color: red;
        height: 40px;
        width: 2750px;
    }

    .content {
        width: 1700px;
        margin: 0 auto;
    }

    .box {
        width: 1600px;
        text-align: center;

    }

    ul li p {
        text-align: center;
        /* text-decoration: 5; */
        font-size: 14px;
        line-height: 25px;
        height: 25px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    /* ul li video{
    height: 150px;
    width: 260px;
    object-fit:fill;  
} */
}
</style>